@jsh-color_1: @palitra-1;
@jsh-color_2: @palitra-2;
@jsh-color_3: #950000;
@jsh-color_4: #999;
@jsh-color_5: #333333;
@jsh-color_6: #A00;
@jsh-color_7: #000;
@jsh-color_8: black;
@jsh-background_color_1: #f5f5f5;
@jsh-background_color_2: #F5F5F5;
@jsh-background_color_3: #FDC055;
@jsh-background_color_4: #fff;
@jsh-background_color_5: #fdfdfd;


@product-br-color: #e7e7e7;
@product-br-radius: 0;
@product-bg: #ffffff;

.product-header {
    padding-bottom: 11px;
    margin: 0 0 24px;
    border-bottom: 1px solid #eee;
}



#mxcpr {
    display: none;
}
.category_description {
    margin-bottom: 20px;
}

/*review*/
/* module categories*/
/**/
/*cart table response*/
.jshop {
    //min-height: 600px; // Временное решение для просмотра
    a {
        text-decoration: none;
        color: @jsh-color_1;
    }
    //h1 {
    //    margin-top: 0;
    //    font-size: 24px;
    //}
    label {
        margin: 0;
        display: inline-block;
        margin-right: 5px;
    }
    input[type="radio"] {
        margin: 0;
    }
    input[type="checkbox"] {
        margin: 0;
    }
    .total {
        font-weight: bold;
    }
    .weightorder {
        text-align: right;
        margin-top: 10px;
    }
    .shippingfree {
        text-align: right;
        margin-top: 10px;
    }
    .price_prod_qty_list {
        .qty_line {
            padding-left: 5px;
            padding-right: 5px;
        }
        .qty_price {
            padding-left: 5px;
            font-weight: bold;
            span.per_piece {
                font-weight: normal;
            }
        }
    }
    span.old_price {
        text-decoration: line-through;
        font-style: normal;
    }
    .list_product_demo {
        padding-top: 10px;
        .download {
            padding-left: 5px;
        }
    }
    .not_available {
        color: @jsh-color_1;
        margin-top: 10px;
    }
    .product_label {
        position: absolute;
        margin-left: -44px;
        margin-top: -5px;
        .label_name {
            font-weight: bold;
            color: @jsh-color_3;
        }
    }
    .downlod_order_invoice {
        float: right;
    }
    .order_payment_params {
        padding-top: 3px;
        font-size: 11px;
    }
    .input_type_radio {
        margin-right: 10px;
        white-space: nowrap;
        display: inline-block;
    }
    .groups_list {
        .title {
            width: 150px;
        }
    }
    td.attributes_title {
        width: 100px;
    }
    span.attributes_description {
        font-style: italic;
        display: block;
    }
    span.freeattribut_description {
        font-style: italic;
        display: block;
    }
    span.extra_fields_description {
        font-style: italic;
    }
    .prod_attr_img {
        vertical-align: top;
    }
    .cart {
        .product_name {
            text-align: left;
        }
    }
    .prod_qty_input {
        input {
            width: 50px;
            margin-bottom: 0;
        }
    }
    .order_shipping_params {
        font-size: 11px;
        padding-top: 3px;
    }
}
table.jshop {
    border-collapse: collapse;
    border: 0;
    width: 100%;
    td {
        vertical-align: top;
    }
}
#jshop_menu_order {
    margin-bottom: 10px;
    border: 1px solid #ccc;
    background-color: @jsh-background_color_1;
}
td.jshop_order_step {
    padding: 2px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    vertical-align: middle;
}
.jshop_order_step {
    a {
        vertical-align: top;
        //text-decoration: underline;
        //font-size: 12px;
    }
}
.table_order_list {
    background-color: @jsh-background_color_2;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    .buttons {
        text-align: center;
        padding-top: 10px;
    }
}
.row-fluid {
    width: 100%;
    *zoom: 1;
    &:before {
        display: table;
        content: "";
        line-height: 0;
    }
    &:after {
        display: table;
        content: "";
        line-height: 0;
        clear: both;
    }
    [class*="sblock"] {
        display: block;
        width: 100%;
        min-height: 28px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        float: left;
        padding-left: 10px;
        &:first-child {
            padding-left: 0;
        }
    }

    .sblock2 {
        width: 50%;
    }
    .sblock3 {
        width: 33.333%;
    }
    .sblock4 {
        width: 25%;
    }
    .sblock5 {
        width: 20%;
    }
    .sblock6 {
        width: 16.666%;
    }
    .sblock7 {
        width: 14.285%;
    }
    .sblock8 {
        width: 12.5%;
    }
    .sblock9 {
        width: 11.111%;
    }
    .sblock10 {
        width: 10%;
    }
    .sblock11 {
        width: 9.09%;
    }
    .sblock12 {
        width: 8.333%;
    }
}
.myorders_block_info {
    margin-bottom: 30px;
    .order_number {
        font-size: 120%;
        margin-bottom: 5px;
    }
    .order_status {
        font-size: 120%;
        margin-bottom: 5px;
    }
}
.myorders_total {
    font-size: 120%;
    .name {
        font-weight: bold;
    }
}
.myorderinfo {
    .userinfo {
        margin-top: 10px;
        margin-bottom: 20px;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 5px;
    }
    .userbillinfo {
        padding-right: 10px;
    }
    .product_head {
        margin-bottom: 5px;
    }
    .order_number {
        font-size: 140%;
    }
    .order_items {
        margin-bottom: 20px;
    }
    .jshop_subtotal {
        margin-bottom: 20px;
    }
    .shipping_block_info {
        margin-bottom: 15px;
    }
    .payment_block_info {
        margin-bottom: 15px;
    }
    .order_comment {
        margin-bottom: 15px;
    }
    .history {
        margin-bottom: 15px;
    }
}
img.jshop_img_thumb {
    padding: 1px;
    text-align: center;
    border: 1px solid #ccc;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    width: 60px;
}
// Блок описания продукта
div.jshop_prod_description {
    padding-bottom: 24px;
}
// Блок описания бонусов
.jshop_prod_bonus{
    color: @palitra-3;
}

div.jshop_prod_attributes {
    padding: 0;
    margin: 0;
    padding-bottom: 10px;
    select {
        width: 140px;
    }
    .attributes_title {
        padding-top: 5px;
    }
}
td.jshop_img_description {
    vertical-align: top;
    text-align: left;
    padding-left: 10px;
}
.jshop_subtotal {
    .value {
        text-align: right;
        white-space: nowrap;
        width: 12%;
    }
    .name {
        padding-right: 10px;
        text-align: right;
        width: 88%;
    }
    .plusshippinginfo {
        font-size: 11px;
        a {
            font-size: 11px;
        }
    }
    .free_discount {
        font-size: 11px;
    }
}
.related_header {
    margin-top: 20px;
    border-bottom: 1px solid #999;
    font-weight: bold;
    margin-bottom: 20px;
}
.td_1 {
    text-align: left;
    font-weight: bold;
}
.td_2 {
    text-align: right;
    font-weight: bold;
}
.jshop_pagination {
    text-align: center;
}
div.jshop_register {
    //width: 80%;
    text-align: left;
    padding: 10px;
    padding-left: 0;
    display: block;
    clear: both;

    .control-group {
        text-align: right;
        //margin-bottom: 12px;
        clear: both;

        .control-label {
            display: inline-block;
            color: #254b25;
        }

        .controls {
            display: inline-block;
            margin-bottom: 8px;
            //width: 60%;
            * {
                width: 100%;
            }
        }

        select {
            padding: 4px;
        }
    }
}
#comjshop_register {
    div.jshop_register {
        &:first-child {
            padding-top: 0;
        }
    }
}
div.last_register_block {
    padding-bottom: 0;
}
.small_header {
    padding-bottom: 5px;
    margin: 0;
    display: block;
    font-size: 15px;
    font-weight: bold;
}
span.jshop_error {
    color: @jsh-color_1;
    font-weight: bold;
    font-size: 14px;
    display: block;
}
span.text_pay_without_reg {
    display: block;
    padding-bottom: 10px;
}
a.policy {
    text-transform: uppercase;
    color: @jsh-color_2;
    font-weight: bold;
}
#active_step {
    font-weight: bold;
    padding: 0;
    margin: 0;
    vertical-align: middle;
}
p.jshop_cart_attribute {
    padding-left: 10px;
    font-weight: bold;
    font-style: italic;
    font-size: 11px;
    margin-top: 1px;
    margin-bottom: 4px;
}
p.jshop_cart_extra_field {
    padding-left: 10px;
    font-weight: bold;
    font-style: italic;
    font-size: 11px;
    margin-top: 1px;
    margin-bottom: 4px;
}
.jshop_price {
    color: #950000;
}
span.attr {
    font-weight: bold;
    font-style: italic;
    font-size: 10px;
}
span.review {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
    display: block;
}
.review_text_not_login {
    margin-top: 10px;
    font-style: italic;
}
textarea.jshop {
    border: 1px solid #b0b1b2;
}
tr.reviews {
    td {
        padding-bottom: 15px;
    }
}
td.reviews {
    padding-left: 45px;
}

.jshop_code_prod {
    color: @jsh-color_4;
    font-weight: normal;
    font-size: 12px;
}
.jshop_list_product {
    // Внешний блок для блока продукта (block_product)
    .sblock_list{
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        //float: left;
        padding-left: 10px;
        margin-left: -0.36em;
        margin-bottom: 10px;
        vertical-align: top;
        //&:first-child {
        //    padding-left: 0;
        //}
    }

    .block_product {
        margin-bottom: 10px;
        border: 1px solid @product-br-color;
        background-color: @product-bg;
        padding: 10px;
        border-radius: @product-br-radius;


        .extra_fields {
            text-align: center;
            .label-name {
              font-size: 13px;
            }
            .data {
              font-size: 13px;
            }
        }
    }
    .jshop {
        .old_price {
            text-align: center;
            color: @jsh-color_4;
            margin-bottom: 5px;
            span {
                text-decoration: line-through;
            }
        }
        .price_extra_info {
            text-align: center;
            margin-bottom: 5px;
        }
        .base_price {
            text-align: center;
            margin-bottom: 5px;
        }
        .not_available {
            margin-top: 0;
            margin-bottom: 5px;
            text-align: center;
        }
    }
    .product {
        td {
            vertical-align: top;
        }
        .image {
            text-align: center;
            margin: 20px 0;
        }
        .name {
            text-align: center;
            font-size: 18px;
            margin-bottom: 5px;
        }
        .review_mark {
            width: 80px;
            margin-left: auto;
            margin-right: auto;
        }
        .description {
            padding-bottom: 5px;
            padding-top: 5px;
        }
        .buttons {
            text-align: center;
            a {
                margin-top: 5px;
            }
        }
        .jshop_price {
            font-size: 18px;
            text-align: center;
            margin-bottom: 5px;
        }
        .taxinfo {
            font-size: 11px;
        }
        .plusshippinginfo {
            font-size: 11px;
            a {
                font-size: 11px;
            }
        }
    }
    .list_related {
        .jshop_related {
            margin-bottom: 15px;
        }
    }
    .image_block {
        padding: 2px;
        display: inline-block;
        img {
            width: 100%;
        }
    }
    .box_products_sorting {
        padding-right: 5px;
        img {
            cursor: pointer;
        }
    }
    .margin_filter {
        height: 8px;
    }
    .box_manufacrurer {
        padding-right: 5px;
        white-space: nowrap;
    }
    .box_category {
        padding-right: 5px;
        white-space: nowrap;
    }
    .filter_price {
        padding-right: 5px;
        white-space: nowrap;
    }
    .box_price_from {
        padding-right: 3px;
    }
    .clear_filter {
        font-size: 10px;
        padding-left: 5px;
    }

    .pagination {
        display: inline-block;
        padding-left: 0;
        margin: @line-height-computed 0;
        border-radius: @border-radius-base;

        li {
            display: inline; // Remove list-style and block-level defaults
            > a,
            > span {
                position: relative;
                float: left; // Collapse white-space
                padding: @padding-base-vertical @padding-base-horizontal;
                line-height: @line-height-base;
                text-decoration: none;
                background-color: @pagination-bg;
                border: 1px solid @pagination-border;
                margin-left: -1px;
            }
            > a{
                color: @pagination-color;
            }
            > span {
                color: grey;
            }

            &:first-child {
                > a,
                > span {
                    margin-left: 0;
                    .border-left-radius(@border-radius-base);
                }
            }
            &:last-child {
                > a,
                > span {
                    .border-right-radius(@border-radius-base);
                }
            }
        }

        > li > a,
        > li > span {
            &:hover,
            &:focus {
                z-index: 2;
                color: @pagination-hover-color;
                background-color: @pagination-hover-bg;
                border-color: @pagination-hover-border;
            }
        }

        > .active > a,
        > .active > span {
            &,
            &:hover,
            &:focus {
                z-index: 3;
                color: @pagination-active-color;
                background-color: @pagination-active-bg;
                border-color: @pagination-active-border;
                cursor: default;
            }
        }

        > .disabled {
            > span,
            > span:hover,
            > span:focus,
            > a,
            > a:hover,
            > a:focus {
                color: @pagination-disabled-color;
                background-color: @pagination-disabled-bg;
                border-color: @pagination-disabled-border;
                cursor: @cursor-disabled;
            }
        }
    }

}
.productfull {
    .old_price {
        color: @jsh-color_4;
        margin-bottom: 5px;
    }
    #block_price {
        font-size: 32px;
        color: @palitra-3;
    }
    .taxinfo {
        font-size: 11px;
    }
    .plusshippinginfo {
        font-size: 11px;
        a {
            font-size: 11px;
        }
    }
    .prod_buttons {
        margin-top: 5px;
        text-align: center;
    }
    div.prod_url {
        text-align: right !important;
    }
    .productweight {
        margin-top: 5px;
    }
    .extra_fields {
        margin-top: 5px;
    }
    .prod_qty {
        display: inline-block;
    }
    .prod_qty_input {
        padding-left: 5px;
        display: inline-block;
    }
    .buttons {
        padding-left: 10px;
        display: inline-block;
    }
    .qty_in_stock {
        margin-bottom: 10px;
    }
    .image_middle {
        width: 100px;
    }
    .manufacturer_name {
        padding-bottom: 10px;
    }
    .prod_free_attribs {
        padding-bottom: 10px;
    }
    .price_prod_qty_list {
        margin-bottom: 10px;
    }
    .button_back {
        padding-top: 10px;
    }
    .text_zoom {
        min-width: 300px;
        padding-bottom: 8px;
        padding-top: 3px;
        img {
            float: left;
            padding-right: 5px;
        }
    }
    a.lightbox {
        &:hover {
            color: @jsh-color_7 !important;
        }
    }
}
.jshop_list_manufacturer {
    .manufacturer {
        td {
            vertical-align: top;
        }
        .image {
            padding-right: 5px;
        }
    }
}
.shipping_weight_to_price {
    margin-left: 24px;
    margin-top: 5px;
    border: 1px solid #000;
    .price {
        padding-left: 5px;
    }
}
span.prod_attr_img {
    padding-left: 5px;
    img {
        width: 20px;
        height: 20px;
    }
}
.radio_attr_label {
    img {
        width: 20px;
        height: 20px;
    }
}
.review_header {
    margin-top: 20px;
    font-weight: bold;
    border-bottom: 1px solid #999;
}
.review_item {
    border-bottom: 1px dotted #999999;
    padding: 10px;
    padding-left: 0;
}
.review_user {
    font-weight: bold;
}
.review_text {
    padding-top: 2px;
    padding-bottom: 2px;
}
.review_mark {
    color: @jsh-color_5;
    height: 18px;
}
#jshop_review_write {
    .inputbox {
        width: 180px;
    }
}
.stars_no_active {
    height: 16px;
    background-image: url(../images/star.gif);
    background-repeat: repeat-x;
    background-position: 0 0;
}
.stars_active {
    height: 16px;
    background-image: url(../images/star.gif);
    background-repeat: repeat-x;
    background-position: 0 -16px;
}
.vendordetailinfo {
    .name {
        width: 130px;
    }
}
.requiredtext {
    padding-bottom: 10px;
    color: @jsh-color_6;
    font-size: 11px;
}
.jshop_profile_data {
    margin-bottom: 10px;
    .name {
        font-weight: bold;
    }
    span {
        font-weight: bold;
    }
    span.subinfo {
        font-size: 10px;
    }
    .jshop_user_group_info {
        font-size: 10px;
    }
}
.jshop_menu_level_0 {
    margin-top: 3px;
    margin-bottom: 3px;
}
.jshop_menu_level_0_a {
    margin-top: 3px;
    margin-bottom: 3px;
    a {
        font-weight: bold !important;
    }
}
.jshop_menu_level_1 {
    padding-left: 10px;
    margin-top: 3px;
    margin-bottom: 3px;
}
.jshop_menu_level_1_a {
    padding-left: 10px;
    margin-top: 3px;
    margin-bottom: 3px;
    a {
        font-weight: bold !important;
    }
}
.jshop_menu_level_2 {
    padding-left: 20px;
    margin-top: 3px;
    margin-bottom: 3px;
}
.jshop_menu_level_2_a {
    padding-left: 20px;
    margin-top: 3px;
    margin-bottom: 3px;
    a {
        font-weight: bold !important;
    }
}
.label_products {
    .block_item {
        margin-bottom: 10px;
    }
}
.top_rating {
    .block_item {
        margin-bottom: 10px;
    }
}
.bestseller_products {
    .block_item {
        margin-bottom: 10px;
    }
}
.latest_products {
    .block_item {
        margin-bottom: 10px;
    }
}
.random_products {
    .block_item {
        margin-bottom: 10px;
    }
}
.jshop_filters {
    .filter_price {
        padding-right: 5px;
    }
    .clear_filter {
        display: block;
        font-size: 10px;
    }
}
.jshop_button_print {
    width: 30px;
    float: right;
    text-align: right;
}

// Список характеристик под товаром
.block_efg {
    width: 480px;
    .extra_fields_group {
        font-weight: bold;
        padding-top: 3px;
    }
    .extra_fields_el{
        overflow: hidden;
        &:after {  content:'............................................................................................................................................';
            display: block;
            white-space: nowrap;
            overflow: hidden;
            color: #d8d7d7;
        }
    }
    .extra_fields_name{
        float: left;
        margin-right: 10px;
    }
    .extra_fields_value{
        float: right;
        margin-left: 10px;
    }
}



.filter_characteristic {
    .characteristic_name {
        padding-top: 5px;
    }
    .characteristic_group {
        font-weight: bold;
        padding-top: 5px;
    }
}
.cart {
    th.product_name {
        padding-left: 5px;
    }
    border-collapse: collapse;
    border: 1px solid #ccc;
    th {
        text-align: center;
        background-color: @jsh-background_color_1;
        padding: 1px;
        color: @jsh-color_7;
        border: 1px solid #ccc;
        table {
            border-collapse: collapse;
        }
    }
    td {
        text-align: center;
        border: 1px solid #ccc;
        padding: 7px 5px;
        vertical-align: top;
    }
    .odd {
        td {
            background-color: @jsh-background_color_4;
            color: @jsh-color_7;
        }
    }
    .even {
        td {
            background-color: @jsh-background_color_5;
            color: @jsh-color_7;
        }
    }
    .taxinfo {
        display: block;
        font-size: 11px;
    }
    .jshop_code_prod {
        font-size: 10px;
    }
    .manufacturer {
        padding: 5px 0 6px 10px;
        font-size: 11px;
        font-weight: bold;
        font-style: italic;
    }
    .list_extra_field {
        padding-top: 3px;
    }
    .cart_reload {
        cursor: pointer;
        a.btn {
            display: none;
        }
    }
    .remove_to_cart {
        .btn-primary {
            display: none;
        }
    }
    .remove {
        .btn-danger {
            display: none;
        }
    }
    .quantity {
        .inputbox {
            width: 50px;
            margin-bottom: 0;
        }
    }
}
.jshop_img_description {
    #list_product_image_thumb {
        display: block;
    }
}
.fielderror {
    background-color: @jsh-background_color_3 !important;
}
.jshop_categ {
    margin-bottom: 20px;
}
.attributgr_name {
    font-weight: bold;
}
#table_shippings {
    .shipping_form {
        display: none;
    }
    .shipping_form_active {
        display: block;
    }
}
.mobile-cart {
    display: none;
}
#all {
    width: auto !important;
    #header {
        width: auto !important;
    }
}
#contentarea {
    width: auto !important;
}
#contentarea2 {
    width: auto !important;
}
.wishlish_buttons {
    margin-top: 20px;
}
.cart_buttons {
    margin-top: 10px;
    .btn-arrow-right, .btn-arrow-left{
        .button-variant(@btn-success-color; @btn-success-bg; @btn-success-border);
    }

}
.cartdescr {
    margin-bottom: 15px;
}
.cart_block_discount {
    margin-top: 35px;
    .inputbox {
        margin-bottom: 0;
    }
    .name {
        float: left;
        min-width: 100px;
        padding-top: 5px;
    }
}
#checkout {
    a {
        img {
            margin-bottom: 5px;
        }
    }
}
.login_block {
    .form-horizontal {
        .control-label {
            width: 100px;
            text-align: left;
            float: left;
        }
        .controls {
            margin-left: 100px;
        }
    }
    .rowpasword {
        margin-bottom: 8px !important;
    }
    .rowremember {
        margin-bottom: 8px !important;
    }
    .rowbutton {
        margin-bottom: 8px !important;
    }
    .rowlostpassword {
        margin-bottom: 8px !important;
    }
}
.logintext {
    margin-bottom: 10px;
}

.block_sorting_count_to_page {

    background-color: #f7f0ea ;
    margin-bottom: 10px;
    padding: 10px;

    .icon-arrow {
        width: auto;
        height: auto;
        img {
            //margin-bottom: 8px;
            margin-left: 3px;
        }
    }

}



.form-horizontal {
    .form_sort_count {
        .control-label {
            text-align: left;
            width: 85px;
            float: left;
        }
        .controls {
            margin-left: 88px;
            margin-right: 15px;
            .inputbox {
                width: 140px;
            }
            .input {
                width: 90px;
            }
        }
    }
    .filter_price {
        label {
            display: none;
        }
    }
    .other_delivery_adress {
        .controls {
            padding-top: 5px;
        }
    }
}
.address_block {
    .form-horizontal {
        .box_button {
            .controls {
                margin-left: 0;
            }
        }
    }
}
.checkout_payment_block {
    #table_payments {
        border-collapse: collapse;
        margin-bottom: 15px;
        .name {
            padding: 5px 0;
        }
        .jshop_payment_method {
            padding-left: 20px;
        }
    }
}
.checkout_shipping_block {
    #table_shippings {
        border-collapse: collapse;
        margin-bottom: 15px;
        .name {
            padding: 5px 0;
        }
    }
}
.checkout_pfinish_block {
    .checkoutinfo {
        margin: 20px 0;
    }
}
.pfinish_comment_block {
    width: 100%;
    //max-width: 325px;
    margin: 0 auto;
    .box_button {
        text-align: center;
        margin-top: 10px;
    }
    .name {
        text-align: center;
        margin-bottom: 3px;
    }
    #order_add_info {
        height: 70px;
        width: 100%;
        //max-width: 320px;
    }
}
.block_rating_hits {
    height: 22px;
    table {
        float: right;
        width: auto;
        border: 0;
    }
}
.clearfix {
    &:before {
        content: "";
        display: table;
        line-height: 0;
        content: "";
        display: table;
        line-height: 0;
    }
    &:after {
        content: "";
        display: table;
        line-height: 0;
        clear: both;
        content: "";
        display: table;
        line-height: 0;
    }
}
.btn.btn-arrow-left {
    background-image: url(../images/arrow_left.gif);
    background-repeat: no-repeat;
    background-position: 8px center;
    padding-left: 20px;
    &:hover {
        background-image: url(../images/arrow_left.gif);
        background-repeat: no-repeat;
        background-position: 8px center;
        padding-left: 20px;
    }
    &:focus {
        background-image: url(../images/arrow_left.gif);
        background-repeat: no-repeat;
        background-position: 8px center;
        padding-left: 20px;
    }
}
.btn.btn-arrow-right {
    background-image: url(../images/arrow_right.gif);
    background-repeat: no-repeat;
    background-position: right 8px center;
    padding-right: 20px;
    &:hover {
        background-image: url(../images/arrow_right.gif);
        background-repeat: no-repeat;
        background-position: right 8px center;
        padding-right: 20px;
    }
    &:focus {
        background-image: url(../images/arrow_right.gif);
        background-repeat: no-repeat;
        background-position: right 8px center;
        padding-right: 20px;
    }
}
div.clear-cart {
    margin-top: 15px;
    text-align: right;
}



@media (min-width: 768px) {
    .form-horizontal {

        .block_sorting_count_to_page {
            .control-label {
                width: 90px;
            }
            .controls {
                margin-left: 95px;
                .inputbox {
                    width: 220px;
                    padding: 2px;
                    border: 1px solid #eee;
                }
            }
            .control-group {
                float: left;
            }
            &:after {
                clear: both;
                content: "";
                display: table;
            }
        }

        .box_products_count_to_page {
            .control-label {
                width: 50px;
            }
            .controls {
                margin-left: 55px;
                .inputbox {
                    width: 60px;
                }
            }
        }
        .box_cat_man {
            .control-group {
                float: left;
            }
            &:after {
                clear: both;
                content: "";
                display: table;
            }
        }
        .filter_price {
            .control-group {
                float: left;
            }
            &:after {
                clear: both;
                content: "";
                display: table;
            }
        }
        .box_price_to {
            .control-label {
                width: 30px;
            }
            .controls {
                margin-left: 30px;
            }
        }
        .filters {
            .box_button {
                .controls {
                    margin-left: 10px;
                }
            }
        }
        .box_price_from {
            .control-label {
                width: 30px;
            }
            .controls {
                margin-left: 40px;
            }
        }
        .box_category {
            .control-label {
                width: 63px;
            }
            .controls {
                margin-left: 65px;
            }
        }
    }
    .pagelogin {
        .register_block {
            .form-horizontal {
                .control-label {
                    text-align: left;
                    width: 100px;
                }
                .controls {
                    margin-left: 115px;
                }
            }
        }
    }

}
@media (min-width: 1400px) {
    .jshop_list_product {
        .sblock_list {
            width: 33.33%;
        }
    }


    .form-horizontal {
        .box_price_from {
            .control-label {
                width: 63px;
            }
            .controls {
                margin-left: 65px;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1399px) {
    .jshop_list_product {
        .sblock_list {
            width: 50%;
        }
    }

    .row-fluid {
        [class*="sblock"] {
            padding-left: 0;
        }
        .sblock2 {
            width: 100%;
        }
        .sblock3 {
            width: 100%;
        }
    }


    .login_block {
        .form-horizontal {
            .controls {
                .inputbox {
                    width: 140px;
                }
            }
        }
    }

    .form-horizontal {

        .block_sorting_count_to_page {
            .control-label {
                width: 90px;
            }
            .controls {
                margin-left: 95px;
                .inputbox {
                    width: 140px;
                    padding: 2px;
                }
            }
            .control-group {
                float: left;
            }
            &:after {
                clear: both;
                content: "";
                display: table;
            }
        }

        .form_sort_count {
            .controls {
                .input {
                    width: 70px;
                }
            }
        }
    }
    .pagelogin {
        .jshop_register {
            .inputbox {
                width: 140px;
            }
            .input {
                width: 126px;
            }
        }
    }
}


@media (max-width: 767px) {
    .row-fluid {
        [class*="sblock"] {
            padding-left: 0;
        }
        .sblock2 {
            width: 100%;
        }
        .sblock3 {
            width: 100%;
        }
        .sblock4 {
            width: 100%;
        }
        .sblock5 {
            width: 100%;
        }
        .sblock6 {
            width: 100%;
        }
        .sblock7 {
            width: 100%;
        }
        .sblock8 {
            width: 100%;
        }
        .sblock9 {
            width: 100%;
        }
        .sblock10 {
            width: 100%;
        }
        .sblock11 {
            width: 100%;
        }
        .sblock12 {
            width: 100%;
        }
    }
    .mobile-cart {
        display: inline-block;
        font-weight: bold;
        width: 40%;
    }
    #jshop_menu_order {
        tr {
            display: block;
            width: 100%;
            td {
                display: inline-table;
                width: 100%;
                line-height: 26px;
            }
        }
    }
    .jshop.cart {
        display: block;
        border: 0;
        tbody {
            display: block;
            border: 0;
        }
        tr {
            display: block;
            width: 100%;
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
            border-top: 1px solid #ccc;
            background-size: 100% 100%;
            color: @jsh-color_8;
            margin-bottom: 20px;
            &:first-child {
                border: 1px solid #ccc;
                margin-bottom: 10px;
                display: none;
            }
            th {
                display: inline-table;
                width: 100%;
                line-height: 26px;
                border: none;
                border-bottom: 1px solid #565656;
            }
            td {
                display: flex;
                border: none;
                border-bottom: 1px solid #ccc;
                padding: 5px 10px;
                text-align: left;
            }
        }
    }
    .cart {
        .odd {
            td {
                background-color: @jsh-background_color_4;
            }
        }
        .even {
            td {
                background-color: @jsh-background_color_4;
            }
        }
        .product_name {
            a {
                display: block;
            }
        }
    }
    .jshop_img_description_center {
        .mobile-cart {
            display: none;
        }
    }
    .login_block {
        .form-horizontal {
            .controls {
                margin-left: 160px;
            }
        }
    }
    .pagelogin {
        .register_block {
            .logintext {
                margin-left: 160px;
            }
            .block_button_register {
                margin-left: 160px;
            }
            .form-horizontal {
                .control-label {
                    text-align: left;
                    width: 150px;
                }
                .controls {
                    margin-left: 160px;
                }
            }
        }
    }
    .jshop_list_product {
        .block_product {
            //border: 1px solid @product-br-color;
            //background-color: @product-bg;
            //padding: 5px;
            //border-radius: @product-br-radius;
        }
    }
    .cart_block_discount {
        .name {
            float: none;
        }
        input {
            display: block;
            margin-top: 10px;
        }
    }
    .productfull {
        .prod_price {
            margin-top: 40px;
        }
    }
}
@media (max-width: 480px) {
    .form-horizontal {

        .block_sorting_count_to_page {
            .control-label {
                width: 90px;
            }
            .controls {
                margin-left: 95px;
                .inputbox {
                    width: 140px;
                    padding: 2px;
                }
                option {
                    font-weight: normal;
                    display: block;
                    white-space: pre;
                    min-height: 1.2em;
                    padding: 0 6px 1px;
                }

            }
            .control-group {
                float: left;
            }
            &:after {
                clear: both;
                content: "";
                display: table;
            }
        }

        .form_sort_count {
            .controls {
                .inputbox {
                    width: 115px;
                }
                margin-right: 0;
                .input {
                    width: 70px;
                }
            }
            .control-label {
                float: left;
                //padding-top: 5px;
            }
        }
        .other_delivery_adress {
            .control-label {
                float: none;
            }
        }
    }
    .block_rating_hits {
        table {
            float: left;
        }
    }
    .productfull {
        .prod_qty {
            display: block;
        }
        .prod_qty_input {
            padding-left: 0;
            margin-top: 10px;
            display: block;
        }
        .buttons {
            padding-left: 0;
            display: block;
            input {
                display: block;
                margin-top: 10px;
            }
        }
    }
    .jshop_payment_method {
        td {
            display: block;
        }
        tr {
            display: block;
        }
    }
    .jshop {
        .downlod_order_invoice {
            float: none;
            padding-bottom: 10px;
        }
    }
    .login_block {
        .form-horizontal {
            .control-label {
                float: none;
            }
            .controls {
                margin-left: 0;
            }
        }
    }
    .pagelogin {
        .register_block {
            .logintext {
                margin-left: 0;
            }
            .block_button_register {
                margin-left: 0;
            }
            .form-horizontal {
                .controls {
                    margin-left: 0;
                }
            }
        }
    }

}
