//
// Close icons
// --------------------------------------------------


.close {
  float: right;
  font-size: (@font-size-base * 1.5);
  font-weight: @close-font-weight;
  line-height: 1;
  color: @close-color;
  text-shadow: @close-text-shadow;
  .opacity(.2);

  &:hover,
  &:focus {
    color: @close-color;
    text-decoration: none;
    cursor: pointer;
    .opacity(.5);
  }

  // Additional properties for button version
  // iOS requires the button element instead of an anchor tag.
  // If you want the anchor version, it requires `href="#"`.
  // See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
  button& {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
  }
}

// Кнопка на теле модульного окна, справа-сверху. Для ekko-lightbox.js
// Появляется только если нету заголовка (data-title)
.body-close {
  float: right;
  font-size: 24px;
  font-weight: 600;

  border: 1px solid #d8d8d8;

  color: #b41c20;
  background-color: #ffffff;

  @ellipce-size: 34px;
  width: @ellipce-size;
  height: @ellipce-size;
  line-height: @ellipce-size;
  border-radius: (@ellipce-size/2);
  position: absolute;
  top: (-@ellipce-size/2);
  right: (-@ellipce-size/2);

  &:hover,
  &:focus {
    color: @close-color;
    text-decoration: none;
    cursor: pointer;
    border: 1px solid gray;
  }

  // Additional properties for button version
  // iOS requires the button element instead of an anchor tag.
  // If you want the anchor version, it requires `href="#"`.
  // See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
  button& {
    padding: 0;
    cursor: pointer;
    //background: transparent;
    //border: 0;
    -webkit-appearance: none;
  }
}